//

import { Echangeur, UserException, EchangeurException } from "./Echangeur";
import MiniFormulaire from "./MiniFormulaire";
import $ from "jquery";
import hash from "./hash";
import { Modal } from 'bootstrap';
import B2bEvent from "./B2bEvent";
//
import { setSessionDroits, setLangue } from "./tools/locales";
//
// Variable globale
const formulaire = {};  // liste des formulaires existants

const CTX = {
    session: null,
    orderManager: null,
    sidebar: null,
    topbar: null,
    disableOnHashChange: false
}
B2bEvent.set("CTX", CTX);

//zz

Echangeur.prototype.onErrorAsync_401 = async function (param) {

    try {

        //if ( window.SESSION !== null)  {
        if (uiContexte && uiContexte.data && uiContexte.data.session !== undefined && uiContexte.data.session !== false) {
            //if(SESSION !== undefined && SESSION!==false){ // timeout
            var PAGE = new MiniFormulaire(); // 
            PAGE.initialise("#authentification");
            PAGE.fillImport(formulaire.loginModal);
            PAGE.data = {};
            const data = await lanceMf(PAGE);
            $("#authentification").empty();
            return data;
        } else { // reload
            throw new EchangeurException(0, "Chargelogin", null, true);
        }
    } catch (e) {
        throw e;
    }
}





async function onHashChange() {
    //
    if (CTX.disableOnHashChange) return;
    //
    var array = hash.toArray();
    //
    const hash0 = (array.length) ? array[0] : {};
    //
    if (!hash0.hash) {
        window.location.hash = "#start";
        return
    } else if (hash0.hash == "logout") {
        console.log('===> logout');
        //
        CTX.session = null;
        CTX.disableOnHashChange = true;
        await Echangeur.logout();
        //
        //
        window.location.hash = "";
        await demarrageClientSession();
        //
        return;
    }
    //
    var last = array[array.length - 1];
    var index = array.length;
    //
    if (index > 1) {
        hash.ecrans[index - 1].sauve();
    }
    //
    $("#hash0").hide();
    //
    for (let i = 0; i < hash.ecrans.length; i++) {
        if (i <= index) hash.ecrans[i].hide();       // Masque tous les écrans précédent  l'écran en cours
        if (i > index) hash.ecrans[i].purge();      // Purge  tous les écrans après l'écrans au dessus  
    }
    var ecran = hash.ecrans[index];
    //
    if (ecran && ecran.mf && ecran.mf.cacheHash && ecran.mf.cacheHash == last.hash && ecran.mf.reprise) {
        console.log("reprise", ecran.mf.cacheHash, last.hash)
        const x = new Echangeur();
        x.hash = last.par;
        ecran.mf.reprise(ecran, x);
    } else {
        if (!formulaire[last.hash]) throw "Formulaire inexistant : " + last.hash
        //

        ecran.mf = new MiniFormulaire();
        ecran.mf.initialise("#" + ecran.id);
        ecran.mf.fillImport(formulaire[last.hash]);
        const x = new Echangeur("getForm_" + last.hash);
        x.hash = last.par;
        await ecran.mf.demarre(last.par, hash.fin, x);
        ecran.mf.cacheHash = last.hash;
    }

    hash.refreshAriane()
}



document.addEventListener("DOMContentLoaded", function (event) {
    demarrageClient();
});


async function demarrageClient() { // a remove wait 

    await setLangue('en');

    const addToFormulaire = function (u) {
        for (var i in u.default) formulaire[i] = u.default[i];
    }
    //
    addToFormulaire(await import("./formulaire/start"));
    //
    addToFormulaire(await import("./formulaire/ordersToAssign"));
    addToFormulaire(await import("./formulaire/ordersToAssignDetail"));
    addToFormulaire(await import("./formulaire/ordersSearch"));
    addToFormulaire(await import("./formulaire/ordersSearchDetail"));
    //
    addToFormulaire(await import("./formulaire/signin"));
    addToFormulaire(await import("./formulaire/sessionExpired"));
    //
    addToFormulaire(await import("./formulaire/sidebar"));

    addToFormulaire(await import("./formulaire/user/users"));
    addToFormulaire(await import("./formulaire/user/userDetail"));
    //
    await demarrageClientSession();
}


async function demarrageClientSession() {

    while (CTX.session == null) {
        try {
            CTX.session = await Echangeur.restnomodal("sessionInit"); //on verifie si on est authentifié   
            CTX.session.isPretenom = (CTX.session.realuser !== CTX.session.username);
        } catch (e) {
            //console.log(e);
            CTX.session = null;
        }
        if (!CTX.session) {
            await demarrageClientSignin();
        }
    }
    setSessionDroits(CTX.session.droits);
    await demarrageClientSuite();
}

function demarrageClientSignin() { // Lancement du formulaire de login
    return new Promise(function (resolve, reject) {
        var mf = new MiniFormulaire("login");
        mf.initialise("#hash0");
        mf.fillImport(formulaire.signin);
        //
        mf.demarre({},
            function () {  // fonction fin du miniformulaire
                //console.log( "fin login");
                resolve();
            }
        );
    }
    )
}


window.onError401 = function () {
    return new Promise(function (resolve, reject) {
        console.log("demarrageClientSessionExpired")
        const element = document.getElementById("sessionexpiredmodal");
        //
        const miniFormulaire = new MiniFormulaire();
        miniFormulaire.initialise(element);
        miniFormulaire.fillImport(formulaire.sessionExpired);
        //
        miniFormulaire.demarre({ session: CTX.session },
            function () {  // fonction fin du miniformulaire
                console.log("fin login");
                modal.hide();
                resolve();
            }
        )
        //
        const options = { backdrop: 'static' };
        const modal = new Modal(element, options)

        modal.show();

        element.addEventListener('hidden.bs.modal', function (event) {
            console.log("modal hide")
        })
    })
}



// Ici, on est authentifié
async function demarrageClientSuite() { // a remove wait 

    $("#sidebar").empty();
    //
    //
    //
    {
        CTX.sidebar = new MiniFormulaire("sidebar");
        CTX.sidebar.initialise("#sidebar");
        CTX.sidebar.fillImport(formulaire.sidebar);
        CTX.sidebar.CTX = CTX;
        await CTX.sidebar.demarre(CTX.session);
    }
    //
    //if ("onhashchange" in window) { return; }
    CTX.disableOnHashChange = false;
    window.onhashchange = onHashChange;
    onHashChange();
}


/*
 * Lance le formulaire de login pour modal & 1er page
 */
const lanceMf = function (mf) {
    return new Promise(function (resolv, reject) {
        mf.fin = function (data) { resolv(data) };
        mf.annuler = function (data) { reject() };
        mf.demarre();
    })
}


export default {};
