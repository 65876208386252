﻿//@ts-check
import $ from 'jquery';
//
import { Echangeur } from "./Echangeur";
import hash from "./hash";

//
//
var logger = {};
//
let errorSelector = $("#erreur").toggle(false)

logger.log = function (message) {
	errorSelector.toggle(true).addClass("show").children("div").first().text(message)
}
logger.empty = function () {
	errorSelector.removeClass("show").toggle(false).children("div").first().empty()
}
errorSelector.children("button").first().on('click', function () {
	logger.empty()
})
$(window).on('hashchange', function() { logger.empty() });

function bind(objet, methode) {
	return function () {
		return methode.apply(objet, arguments);
	};
}

function valeurFonction(f, p) {
	if (typeof f === "function") return f(p);		// if ($.isFunction(f)) return f(p); $.isFunction deprecated
	return f;
}

var compteurUuid = 0;



class MiniFormulaire {
	constructor(h) {
		this.uuid = "" + (compteurUuid++);
		this.hash = null;
		//
		this.$c = {}; 		// conteneur
		this.assertions = [];	// liste assertions du formulaire
		this.formErreurs = [];	// liste assertions du formulaire
		this.retour = null; 	// fonction de retour ;
		this.titre = null;		// Pour debug
		this.datamap = {};		// Pour debug
		this.scrolltop = 0;
	}
	toggle(value) {
		this.$c.toggle(value);
	}
	/**
		 * @param  {any} conteneur jquery selector
		 * @return {MiniFormulaire} this
		 */
	initialise(conteneur) {
		if (!conteneur) throw "initialise sans conteneur";
		this.$c = $(conteneur);
		this.toggle(false);
		this.titre = this.constructor.name + "." + conteneur;
		return this;
	};
	onBlur(echangeur) { }
	onFocus(echangeur) { }

	setTitle(t) {
		this.title = t;
		document.title = t;
		hash.refreshAriane();
	}
	async demarre(pHash, retour, echangeurSource) {
		this.retour = retour;
		this.hash = pHash;
		var echangeur = echangeurSource || new Echangeur("get" + this.titre);
		echangeur.name = "getData";
		echangeur.hash = pHash;
		await this.load(echangeur);
		return this;
	}
	async lance(pHash, retour, echangeurSource) {
		return this.demarre(pHash, retour, echangeurSource)
	}
	url(echangeur) { };
	async load(echangeur) {
		//
		this.url(echangeur);		// Chargement des actions 
		//
		//try {
		await echangeur.runAsync();
		if (echangeur.erreur) {
			this.onError("Erreur JSON", echangeur.erreur.message, echangeur);
		} else {
			await this.loader(echangeur);				// Affichage du contenu
			this._onFirstLoad(echangeur);		// Affichage du contenu. Une seule fois
			if (this.title) {
				document.title = valeurFonction(this.title, echangeur);
			}
			//
			this.toggle(true);					// affichage de l'écran
			//
			await this.onLoad(echangeur);
			this.onFocus(echangeur);
		}
	}
	// 
	async onLoad(echangeur) { }
	//
	// Déclenchée une seule fois pour activer les clicks sur les boutons
	_onFirstLoad(echangeur) {
		var isNotSet = (this.$c.attr("data-i")) ? false : true;
		if (isNotSet) {
			this.$c.attr("data-i", "I");
			if (this.onFirstLoad) this.onFirstLoad(echangeur);
		}
	}
	onFirstLoad(echangeur) { }

	fin(echangeur) {
		if (this.onBlur) this.onBlur(echangeur);
		this.toggle(false);
		if (this.retour) this.retour(echangeur);
	}
	async loader(p) { }
	onSubmit(echangeur, parametre) { return true; };

	async submit(callback, parametre, onFinally) {
		try {
			if (!onFinally) onFinally = function () { };
			callback = callback || bind(this, this.onSuccess);
			//
			var echangeur = new Echangeur();
			await this._serialize(echangeur);	// autres données type formData, images, pièces jointes
			//
			var confirm = null;
			try {
				confirm = this.onSubmit(echangeur, parametre);
			} catch (e) {
				console.log(e)
				confirm = false;
			}
			if (confirm === false) return onFinally(false);
			//
			//
			this.action(echangeur, parametre);	// Chargement des actions
			//
			await echangeur.runAsync();
			if (echangeur.erreur) {
				this.onError("Erreur JSON", echangeur.erreur.message, echangeur);
			} else {
				onFinally(false);
				callback(echangeur);
			}
		} catch (e) {
			console.log(e);
			this.onError("Erreur JSON", null, e);
			onFinally(false);
		}
	}

	onSuccess(echangeur) {
		this.fin(echangeur);
	}
	/**
	 * récupere les données saisies du DOM dans le miniformulaire
	 * suivant les fonctions disponibles dans le miniformulaire
	 * lance serializeAsync, serialize ou par défaut, serializeBlock
	 * @return void
	 */

	async _serialize(echangeur) {
		
		if (typeof this.serialize === "function") {
			console.log(" appel serialize");
			echangeur.form = this.serialize(echangeur);	// Données postées
			return;
		}
		echangeur.form = this.$c.serializeBlock();
	}

	serializeBlock(bloc) {
		var $a = bloc || this.$c;
		return $a.serializeBlock();
	}
	
	onError(etat, message, p) {
		if (this.$e) {
			this.$e.html(this.onErrorFormat(etat, message, p)).show();
			this.$e.html(this.onErrorFormat(etat, message, p)).show()
		} else {
			logger.log(message || p);
			//window.alert(message || p);
		}
	}
	onErrorFormat(etat, message, p) { return message || p; };
	debug(titre, quoi) { };

	action(echangeur, parametre) { };

	initDate(classe) {
		console.log("MiniFormulaire.prototype.initDate à corriger");
		this.c$(classe).initDatePicker();
	}

	/**
	 * Remplit un formulaire avec les données fournies
	  * balise ayant un attribut data-multiple 
	 * balise ayant un attribut data-f  
	 * balise ayant un attribut name
	 *
	 * @param  {object} 	data 
	 * @param  {object}	 	$conteneur ou this.$c 
	 * @return {MiniFormulaire} conteneur jquery selector
	 */
	populate(data, $conteneur) {
		var $a = $conteneur || this.$c
		$a.populate(data, this.datamap);
		return this;
	}


	/**
	 * @param  {String} expression du champ
	 * @return {Object} field
	 */
	c$(expression) {
		return $(expression, this.$c);
	};
	/**
	 * @param  {String} expression du champ
	 * @return {Object} field
	 */
	t$(expression) {
		return $(expression, this.$t);
	};

	/**
	 * Rempli le html et le js d'un miniformulaire à partir d'un objet {html,js}
	 * @param  {{html,js}} formulaire
	 * @return {MiniFormulaire} this
	 */
	fill(formulaire) {
		var THIS = this;
		this.$c.html(formulaire.html);
		this.$t = this.c$("._TEMPLATE").clone();
		this.c$("._TEMPLATE").remove();
		//
		this.dictionnaire = {};
		$("[data-dic]", this.$t).each(function () {
			var k = $(this).attr("data-dic");
			if (k) THIS.dictionnaire[k] = $(this).html();
		})
		//

		var u = eval("(" + formulaire.js + ")");
		if ($.isFunction(u)) u = u();
		for (var a in u) this[a] = u[a];
		return this;
	};
	fillImport(formulaire) {
		var THIS = this;
		this.$c.html(formulaire.html);
		this.$t = this.c$("._TEMPLATE").clone();
		this.c$("._TEMPLATE").remove();
		//
		this.dictionnaire = {};
		$("[data-dic]", this.$t).each(function () {
			var k = $(this).attr("data-dic");
			if (k) THIS.dictionnaire[k] = $(this).html();
		})
		var u = formulaire.js;
		for (var a in u) this[a] = u[a];
		return this;
	};
	dic(k) {
		if (k in this.dictionnaire) return this.dictionnaire[k]
		return "??" + k;
	}
	/**
	 * Crée un miniformulaire dans le conteneur, avec le html et js du formulaire et lance et charge avec les données de echangeur
	 * @param  {String} conteneur
	 * @param  {{html,js}} formulaire
	 * @param  {Echangeur} echangeur
	 * @return {MiniFormulaire} this
	 */
	createChild(conteneur, formulaire, echangeur) {
		var mf = new MiniFormulaire();
		mf.initialise($(conteneur, this.$c));
		mf.fill(formulaire);
		mf.parent = this;
		if (echangeur) mf.load(echangeur);
		return mf;
	}
	hashLance(m, p, o, newtab) {
		hash.lance(m, p, o, newtab);
	}
	hashChaine(m, p) {
		hash.chaine(m, p);
	}
	hashReplace(m, p) {
		hash.replace(m, p);
	}
	importeParent() {
		var array = hash.toArray();
		if (array.length > 1) return hash.ecrans[array.length - 2].cache
	}
	async rest(fonction, parametres, doc, d) {
		let data = null;
		try {
			data = await Echangeur.rest(fonction, parametres, doc, d);
		} catch (e) {
			this.onError("?", e.message, e);
		}
		return data;
	}
}



MiniFormulaire.prototype.aExclure = function ($input) {
	var aExclure = "._tmp";
	console.log($input.closest(aExclure).length > 0);
	return ($input.closest(aExclure).length > 0);
};


MiniFormulaire.prototype.verifBloc = function ($b) {
	this.nettoieErreur();
	this.formErreurs = [];
	var THIS = this;
	// 
	$("input._required", $b).each(function () {
		var $t = $(this);
		if (!THIS.aExclure($t) && ($t.val() == null || $t.val() == "")) {

			THIS.formErreurs.push({ $champ: $(this), message: $(this).attr("data-mf-error-message") || "Required" });
		}
	})
	$("select._required", $b).each(function () {
		var $t = $(this);
		if (!THIS.aExclure($t) && $t.val() == null || $t.val() == "") THIS.formErreurs.push({ $champ: $(this), message: "Required" });
	})

	
	//
	$.each(this.assertions, function () {
		if (this.test && !this.test()) {
			THIS.formErreurs.push({ $champ: this.$champ, message: this.message });
		}
	})
	//
	console.log(this.formErreurs.length);
	console.log(this.formErreurs);
	if (this.formErreurs.length !== 0) {
		this.afficheErreurs();
		throw new Error(this.formErreurs.length + " erreurs ");
	}
};

MiniFormulaire.prototype.verifForm = function () {
	this.nettoieErreur();
	this.formErreurs = [];
	var THIS = this;
	// 
	$("input._required", this.$c).each(function () {
		var $t = $(this);
		if (!THIS.aExclure($t) && ($t.val() == null || $t.val() == "")) THIS.formErreurs.push({ $champ: $(this), message: "Required" });
	})
	$("select._required", this.$c).each(function () {
		var $t = $(this);
		if (!THIS.aExclure($t) && $t.val() == null || $t.val() == "") THIS.formErreurs.push({ $champ: $(this), message: "Required" });
	})

	
	//
	$.each(this.assertions, function () {
		if (this.test && !this.test()) {
			THIS.formErreurs.push({ $champ: this.$champ, message: this.message });
		}
	})
	//
	console.log(this.formErreurs.length);
	console.log(this.formErreurs);
	if (this.formErreurs.length !== 0) {
		this.afficheErreurs();
		throw new Error(this.formErreurs.length + " erreurs ");
	}
};
MiniFormulaire.prototype.nettoieErreur = function () {
	//console.log("nettoieErreur");
	$(".has-error", this.$c).removeClass("has-error");
	$("._contErreur", this.$c).remove();
	$("._assert", this.$c).remove();
}
MiniFormulaire.prototype.afficheErreurs = function () {
	var THIS = this;
	console.log("afficheErreurs");
	$.each(this.formErreurs, function () {
		THIS.afficheErreur(this.message, this.$champ);
	})
}


MiniFormulaire.prototype.afficheErreur = function (message, $champ) {
	if ($champ && $champ.length && $champ.is(":visible")) {
		if ($champ.parent().is("td")) {
			console.log("is TD");
			$champ.addClass("has-error");
		} else {
			var $parent = $champ.closest("[class^='col']");
			if ($parent.length == 0) $parent = $champ.closest(".form-group,.row");
			//$(".has-error", 	$parent).removeClass("has-error");
			//$("._contErreur", 	$parent).remove();
			$parent.addClass("has-error");
			if (message) {
				const $contMessage = $("<div>").addClass("form-control-static _contErreur help-block erreurMessage").append($("<small class='text-danger'><small>").text(message));
				$parent.append($contMessage);
			}
		}
	} else {
		this.$c.prepend($('<div>').addClass("alert alert-danger _assert").attr("role", "alert").text((message) ? message : " Erreur !")); //<div class="alert alert-danger" role="alert">...</div>
	}

}



MiniFormulaire.prototype.assert = function (f, message, $champ) {
	if (!(f)) {
		this.afficheErreur(message, $champ);
		throw new Error(message);
	}
}

export default MiniFormulaire;

