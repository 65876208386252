//@ts-check
import $ from "jquery"
class Ecran {
	constructor(id) {
		this.id = id;
		this.hash = "";
		this.mf = null;
		this.cache = "";
		this._position = 0;
	}
	purge() {
		this.hide();
		this.hash = "";
		this.mf = null;
		this.cache = "";
		
	}
	hide() {		
		$("#" +this.id).attr("data-i", "").hide(); 
	}
	sauve() {
		this._position = $(window).scrollTop() || 0;
	}
	restaure() {
		if (this._position) {
			$(window).scrollTop(this._position);
			//this._position = 0;
		}		
	}
	
}



let hash = {
	ecrans:(['hash1', 'hash2', 'hash3', 'hash4', 'hash5', 'hash6','hash7']).map(id=>new Ecran(id)),
	breadcrumb: [],
	refreshAriane: function () {
		var array = hash.toArray();
		hash.breadcrumb.splice(0, hash.breadcrumb.length);

		for (var i = 0; i < array.length; i++) {
			var ecran = hash.ecrans[i];
			if (ecran.mf) {
				var text = ecran.mf.ariane || ecran.mf.title || array[i].hash;
				var url = hash.toHash(array.slice(0, i + 1));
				hash.breadcrumb.push({ href: url, text: text, hash: array[i].hash, isActive: (array.length == i + 1) });
			} else {
				var url = hash.toHash(array.slice(0, i + 1));
				hash.breadcrumb.push({ href: url, text: array[i].hash, hash: array[i].hash, isActive: (array.length == i + 1) });
			}
		}
	},
	back: function () {
		window.history.back();
	},
	toArray: function () {		// split hash to array 
		// partant de #hash1&p11=v1&p12=v2#hash2&p21=v1&p2=v22...           
		// construit un objet  [  {hash:"hash1", par:{p11:"v1", p12:"v2"}}, {hash:"hash2", par:{p21:"v1", p22:"v2"}}, ...]
		var array = window.location.hash.substring(1).split('/');
		//
		return array.map(
			function (u) {
				var items = u.split('&');
				const formname = items.shift();
				const par = {};
				for (const p of items) {
					var z1 = p.indexOf("=");
					par[p.substring(0, z1)] = decodeURIComponent(p.substring(z1 + 1));
				}
				return { hash: formname, par: par};
			}
		);
	},
	// Fonction inverse de la précédente toArray
	toHash: function (array) {
		const items = array.map(function (u) {
			var h = u.hash;
			for (let key in u.par) h += '&' + key + "=" + encodeURIComponent(u.par[key])
			//
			return h;
		});
		return "#" + items.join("/");
	},
	
	force: function (array) {
		window.location.hash = hash.toHash(array);
	},
	forcereplace: function (array) {
		window.location.replace(hash.toHash(array));
	},
	seq: 0,
	menu: function (m, p) {
		p = p || {};
		p.seq = hash.seq++;
		window.location.hash = hash.toHash([{ hash: m, par: p }]);
	},
	home: function (page) {
		window.location.hash = page || "";
	},
	chaine: function (m, p) {
		var array = hash.toArray();
		array.pop();
		if (m.hash) {
			array.push({ hash: m.hash, par: p })
		} else {
			array.push({ hash: m, par: p })
		}
		window.location.hash = hash.toHash(array);
	},
	replace: function (m, p) {
		var array = hash.toArray();
		array.pop();
		if (m.hash) {
			array.push({ hash: m.hash, par: p })
		} else {
			array.push({ hash: m, par: p })
		}
		window.location.replace(hash.toHash(array));
	},
	lance: function (formName, par, o, newtab) {
		var array = hash.toArray();
		array.push({ hash: formName, par: par })
		//
		if (newtab) {
			window.open(hash.toHash(array), '_blank');
		} else {
			window.location.hash = hash.toHash(array);
		}
	},
	
	fin: function () {
		var array = hash.toArray();
		array.pop();
		window.location.hash = hash.toHash(array);
	},
	
};

export default hash;
