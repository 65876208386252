//@ts-check
import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr';
flatpickr.localize(French);

import { dictionnaire } from '../i18n/fr';

let langue = '';
let droits = [];

const devises = {
  "USD": { d: 2, symbol: "$", country: "en-US", name: "US Dollar" },
  "EUR": { d: 2, symbol: "€", country: "fr-FR", name: "Euro" },
  "GBP": { d: 2, symbol: "£", country: "en-GB", name: "British Pound Sterling" },
  "HKD": { d: 2, symbol: "HK$", country: "en-HK", name: "Hong Kong Dollar" },
  "JPY": { d: 0, symbol: "JPY", country: "ja-JP", name: "Japanese Yen" },
  "CNY": { d: 0, symbol: "CNY", country: "zh-CN", name: "Chinese Yuan" },
  "CHF": { d: 2, symbol: "CHF", country: "de-CH", name: "Swiss Franc" },
}

function getDevise(d) {
  if (d in devises) return devises[d];
  return { d: 2, symbol: d };
}

const formatCurrency = (number, devise) => {
  const country = devises[devise].country;
  return number
    ? new Intl.NumberFormat(country,
      { style: 'currency', currency: devise }).format(number)
    : ''
}

const formatQt = new Intl.NumberFormat('en');
const formatHt = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
const formatPrix = new Intl.NumberFormat('en', { maximumFractionDigits: 2 });

class B2bTraduction {
  static async setSessionDroits(p) {
    //console.log("setSession", p )
    droits = p;
  }
  static async setLangue(p) {
    /* console.log("B2bTraduction", "chargement langue", p)
     const settings = {
         method: 'GET',
         headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
     };
     const fetchResponse = await fetch(`langue/${p}.json`, settings);
     */
    //dictionnaire = {}; //await fetchResponse.json();
    langue = p;
  }
  /**
   * retourne la traduction d'un libellé à partir d'une clé du genre url
   * @param {String} key     clé de recherche : /linelist/edit/button
   * @param {String} prefixe si le chemin est relatif (ne commence pas par un / )
   * @returns String traduction
   */
  static traduit(key, prefixe) {
    const keys = key.split("/");
    let o;
    if (!keys[0]) {
      keys.shift();
      o = dictionnaire;
    } else {
      o = dictionnaire[prefixe];
    }
    //
    while (keys.length) {
      if (!o) return B2bTraduction.erreur(key);
      const k = keys.shift()
      if (!k) return B2bTraduction.erreur(key);
      o = o[k]
    }
    return o || B2bTraduction.erreur(key);
  }
  static erreur(p) {
    //erreurs.push(p);
    /* console.log("Traduction manquante", langue, p); */
    return `???${p}`;
  }
  static toPrix(value, dev) {
    let v = formatHt.format(Number.parseFloat(value)).replace(/\,/g, " ");
    const devise = (dev) ? getDevise(dev) : null;
    if (devise) {
      return `${v} ${devise.symbol}`;
    } else {
      return v;
    }
  }

  static may(value) {
    if (value in droits) return droits[value];
    /* console.log("invalid may : " + value) */
    return false;
  }

  static toDate(value) {
    if (!value) return "";
    let a = flatpickr.parseDate(value, "Ymd");
    if (!a) return "";
    return flatpickr.formatDate(a, "j-M-Y");
  }

}

const setSessionDroits = B2bTraduction.setSessionDroits;
const traduit = B2bTraduction.traduit;
const setLangue = B2bTraduction.setLangue;
const toPrix = B2bTraduction.toPrix;
const toDate = B2bTraduction.toDate;
const may = B2bTraduction.may;
const getDroits = function () {
  const array = [];
  for (let key in droits) if (droits[key]) array.push(key);
  return array;
}

const flatpickrConfig = {
  altInput: true,
  altFormat: "j-M-Y", // j F Y
  dateFormat: "Ymd",
  allowInput: true,
};

export {
  flatpickrConfig,
  setSessionDroits as setSessionDroits,
  traduit,
  setLangue,
  toPrix,
  toDate,
  may,
  getDroits,
  formatCurrency
};
