const dictionnaire = {
  /**
   * Tools
   */
  tools: {
    enable: { title: "Activation" },
    disable: { title: "Désactivation" },
    enableAccount: { title: "Activer le compte" },
    disableAccount: { title: "Désactiver le compte" },
    enableAccountSuccess: { title: "Compte activé" },
    disableAccountSuccess: { title: "Compte désactivé" },
    enableAccountError: { title: "Erreur lors de l'activation du compte" },
    disableAccountError: { title: "Erreur lors de la désactivation du compte" },

    delete: { title: "Suppression" },
    deleteAccount: { title: "Supprimer le compte" },
    deleteAccountSuccess: { title: "Compte supprimé" },
    deleteAccountError: { title: "Erreur lors de la suppression du compte" },

    enableMail: { title: "Envoyer le mail d'activation" },
    enableMailSuccess: { title: "Mail d'activation envoyé" },
    enableMailError: { title: "Erreur lors de l'envoi du mail d'activation" },
  },

  /**
   * Sidebar
   */
  sidebar: {
    logout: { button: "Déconnexion" },
    account: { button: "Mon compte" }
  },

  /**
   * Générale
   */
  global: {
    // Liste des pages
    start: { title: "Tableau de bord" },
    ordersToAssign: { title: "Reste à affecter" },
    ordersSearch: { title: "Historique de commandes" },
    users: { title: "Utilisateurs" },

    // Tableau et en-tête
    po: { label: "Commande Magento" },
    clientK: { label: "Code client" },
    nom: { label: "Nom client" },
    status: { label: "Statut" },
    dateCre: { label: "Date" },
    address: { label: "Adresse" },
    phone: { label: "Téléphone" },
    proposition: { label: "Proposition" },
    comment: { label: "Commentaire" },
    product: { label: "Produit" },
    description: { label: "Description" },
    familly: { label: "Fam" },
    collection: { label: "Coll" },
    qt: { label: "Qté." },
    ordered: { label: "Commandé" },
    qtnotaffect: { label: "A affecter" },
    qtattente: { label: "En attente" },
    qtexped: { label: "Expédié" },
    qtcanceled: { label: "Annulé" },
    total: { label: "Total" },
    port: { label: "Port" },
    price: { label: "Prix" },
    frn: { label: "Frn." },
    ann: { label: "Ann." },
    dateStart: { label: "Date de début" },
    dateEnd: { label: "Date de fin" },

    // Historique
    history: { label: "Historique" },
    internalReference: { label: "Commande Y2" },
    depot: { label: "Dépôt" },
    dateCreFull: { label: "Date de création" },
    type: { label: "Type de produit" },
    qtOrdered: { label: "Qté. commandée" },
    qtc: { label: "Qté. demandée" },
    qte: { label: "Qté. expédiée" },
    qtr: { label: "Qté. refusée" },
    estPortTrue: { label: "Port et traitement" },
    estFinancierTrue: { label: "Carte club ou bon cadeau" },
    estFinancierFalse: { label: "Marchandise" },
    unavailable: { label: "Non disponible" },
    detail: { label: "Détail de la commande N°" },

    // Boutons d'action
    back: { button: "Retour" },
    search: { button: "Rechercher" },
    clear: { button: "Réinitialiser" },
    affect: { button: "Affecter" },
    next: { button: "Suivant" },
    previous: { button: "Précédent" },
    complete: { button: "Completer" },
    remove: { button: "Effacer" },
    close: { button: "Fermer" },

    // Pagination
    result: { label: "Résultat" },

    // Autres
    cmdNumber: { label: "Commande N°" },
    placeholder: { input: "Rechercher..." },
  },

  /**
   * Compte et session
   */
  account: {
    login: {
      button: "Se connecter",
      error: "Échec de la connexion"
    },
    back: { button: "Retour" },
    mail: {
      label: "Adresse email",
      error: "Email requis",
      placeholder: "nom@adresse.fr",
      p1: "L'email a été envoyé",
      p2: "Veuillez consulter votre boîte aux lettres",
      span: "Impossible de trouver votre compte"
    },
    password: {
      label: "Mot de passe",
      error: "Mot de passe requis",
      placeholder: "Votre mot de passe"
    },
    passwordForgot: { button: "Mot de passe oublié ?" },
    passwordReset: {
      button: "Réinitialiser votre mot de passe",
      label: "Vous vous souvenez de votre mot de passe ?"
    },
    choose: {
      title: "Choisissez un nouveau mot de passe pour",
      label1: "Nouveau mot de passe",
      label2: "Confirmez le nouveau mot de passe",
      errorPassword: "Champ obligatoire",
      errorMatch: "Le mot de passe ne correspond pas",
      button: "Définir le mot de passe",
      p: "Le mot de passe a été modifié",
      rules: "Le mot de passe doit respecter des exigences de complexité",
      expired: "Le lien d'activation a expiré"
    },
    continue: { button: "Continuez" }
  },
  session: {
    heading: {
      expired: "La session a expiré",
      reset: "Réinitialisation du mot de passe"
    },
    subheading: {
      expired: "Votre session a expiré, veuillez vous reconnecter",
      reset: "Saisissez votre adresse email pour obtenir un lien de réinitialisation"
    },
    alert: {
      p1: "L'email a été envoyé",
      p2: "Veuillez consulter votre boîte aux lettres",
      error: "La connexion a échoué",
      success: "Connexion réussie"
    },
    password: {
      label: "Mot de passe de",
      button: "Je ne suis pas"
    }
  }
}
export { dictionnaire };