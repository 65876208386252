const data = {};

class B2bEvent {
  static set(key, value) {
    const identique = (data[key] === value);
    if (!identique) {
      data[key] = value;
      const name = `${key}.change`;
    }
  }
  static get(key) {
    return data[key];
  }
}

export default B2bEvent;